<template>
  <span class="regular-12 text-black" v-if="value.import_finished_at">
    <div
      class="badge me-1"
      :class="[
        value.import_statistics?.inserted !== value.import_statistics?.total
          ? 'bg-info'
          : 'bg-success',
      ]"
    >
      {{ $t(`catalogLastLog.fileRows`, {total: value.import_statistics?.total}) }}
    </div>
    <div
      class="badge"
      :class="{ 'bg-danger': value.import_statistics?.failed }"
      v-on:mouseenter="focusClickPosition"
      v-on:click="focusClickPosition"
      v-on:mouseout="focusOut"
    >
     {{ value.import_statistics?.failed > 0 ? value.import_statistics?.failed+" errors" : "No errors" }}
    </div>
    <div
      class="toast fade popup position-fixed text-start"
      aria-live="assertive"
      aria-atomic="true"
      ref="modal"
      v-on:mouseout="focusOut"
      :style="{ top: clickY + 'px', left: clickX + 'px' }"
    >
      <div class="toast-header">
      <strong class="me-auto">
        {{ $t("catalogLastLog.errors") }}
      </strong>
      </div>
      <div class="toast-body">
        <div v-for="errors in value.import_errors" :key="errors" class="mb-2 row white-space-normal">
          <div v-if="typeof errors === 'object'" class="col-12">
            <div v-for="error in errors" :key="error">
              {{error}}
            </div>
          </div>
          <div v-else>
            {{errors}}
          </div>
        </div>
      </div>
  </div>
  </span>
  <span class="regular-12 text-black" v-else>
     <div class="badge bg-dark">
      {{ $t("catalogLastLog.importInProcess") }}
    </div>
  </span>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "CatalogLastLog",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
      clickX: 0,
      clickY: 0,
      errorModal: null,
    };
  },
  mounted() {
    this.errorModal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    focusClickPosition(event) {
      this.clickX =
        document.documentElement.clientWidth - 420 < event.clientX
          ? document.documentElement.clientWidth - 420
          : event.clientX;
      this.clickY =
        document.documentElement.clientHeight - 300 < event.clientY
          ? document.documentElement.clientHeight - 300
          : event.clientY;
      this.errorModal.show();
    },
    focusOut() {
      this.errorModal.hide();
    },
  },
};
</script>
